<template>

  <a-drawer :title="title"
            :maskClosable="true"
            :width="drawerWidth"
            placement="right"
            :closable="true"
            @close="handleCancel"
            :visible="visible"
            style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;">

    <template slot="title">
      <div style="width: 100%;">
        <span>{{ title }}</span>
        <span style="display:inline-block;width:calc(100% - 51px);padding-right:10px;text-align: right">
          <a-button @click="toggleScreen"
                    icon="appstore"
                    style="height:20px;width:20px;border:0px"></a-button>
        </span>
      </div>

    </template>

    <a-spin :spinning="confirmLoading">
      <ax-form ref="formBox1" :formBuilder="formBuilder">
        <template slot="fileId">
          <a-upload
            name="file"
            :multiple="false"
            :file-list="fileList1"
            :customRequest="handleUpload"
            @change="handleChange"
          >
            <a-button size="small"> <a-icon type="upload" />上传附件</a-button>
          </a-upload>
        </template>
      </ax-form>
    </a-spin>

    <div class="drawer-bootom-button"
         v-show="!disableSubmit">
      <a-popconfirm title="确定放弃编辑？"
                    @confirm="handleCancel"
                    okText="确定"
                    cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit"
                type="primary"
                :loading="confirmLoading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import api from './api'
import { initGridFormData } from '@/common/tools'

export default {
  name: 'FileModal',
  data () {
    return {
      showUploadList: false,
      positionDep: [],
      // 获取当前选中的部门id 保存时查询相应的角色
      positionDeptId: '',
      userGroup: [],
      modalWidth: 800,
      drawerWidth: 700,
      modaltoggleFlag: true,
      confirmDirty: false,

      fileId: '', // 保存文件id
      disableSubmit: false,

      validatorRules: {

      },
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      uploadLoading: false,
      confirmLoading: false,
      formBuilder: initGridFormData(
        [
          {
            label: '文件名称',
            type: 'input',
            model: 'name',
            options: {
              placeholder: '请输入文件名称',
              allowClear: true
            },
            rules: [{ required: true, message: '请输入文件名称' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '文件描述',
            type: 'input',
            model: 'description',
            options: {
              placeholder: '请输入文件描述',
              allowClear: true
            },
            rules: [{ required: true, message: '请输入文件描述' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '所属目录',
            type: 'treeSelect',
            model: 'lbraryId',
            options: {
              placeholder: '请选择所属目录',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择所属目录' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '所属部门',
            type: 'select',
            model: 'fileDept',
            options: {
              placeholder: '请选择所属部门',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择所属部门' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '文件类型',
            type: 'select',
            model: 'fileType',
            options: {
              placeholder: '请选择文件类型',
              allowClear: true
            },
            rules: [{ required: true, message: '请选择文件类型' }],
            formItem: { colon: false },
            col: { span: 24 }
          },
          {
            label: '附件上传',
            type: '',
            model: 'fileId',
            options: {},
            rules: [{ required: true, message: '请上传文件' }],
            formItem: { colon: false },
            col: { span: 24 }
          }
        ],
        {
          layout: 'horizontal',
          labelWidth: 90
        }
      )
    }
  },

  computed: {
  },
  methods: {
    // 窗口最大化切换
    toggleScreen () {
      if (this.modaltoggleFlag) {
        this.modalWidth = window.innerWidth
      } else {
        this.modalWidth = 800
      }
      this.modaltoggleFlag = !this.modaltoggleFlag
    },
    refresh () {
      this.selectedDepartKeys = []
      this.checkedDepartKeys = []
      this.checkedDepartNames = []
      this.checkedDepartNameString = ''
      this.fileId = ''
    },
    edit (record) {

    },
    //
    loadCheckedDeparts () {
      const that = this
      if (that.userId) {
        api.getFileById({ id: that.fileId }).then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            that.checkedDepartKeys.push(res.data[i].key)
          }
          that.userDepartModel.departIdList = that.checkedDepartKeys
        })
      } else {
        this.checkedDepartNameString = this.positionDep.title
        this.checkedDepartKeys.push(this.positionDep.key)
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.disableSubmit = false
    },
    handleSubmit () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          if (!this.model.id) {
            formData.id = this.fileId

            obj = api.addUser(formData)
          } else {
            obj = api.updateFile(formData)
          }
          obj.then((res) => {
            that.$message.success(res.message)
            that.$emit('ok')
          }).finally(() => {
            that.confirmLoading = false
            that.close()
          })
          that.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.close()
    },
    handleChange () {},
    // 根据屏幕变化,设置抽屉尺寸
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.drawerWidth = screenWidth
      } else {
        this.drawerWidth = 700
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

.upload {
  margin-left: 134px;
}
</style>
